<template>
  <section class="faq">
    
      
     
      
  
         
    <div class="row">
        
 

            <!-- COL1 -->   
             <div class="col-md-12">
                 
                 
                 
                 
               <div class="row" v-show='rldata.is_fin_form'>   
                     
                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
                            <h4 class="mt-2">Отчет отправлен!</h4>
                            <p class="mt-2">Отчет по заявке № {{ rldata['r_tickets']['ticknum'] }} отправлен. Спасибо!</p>
                     </div> 
                   </div> 
                 </div> 
                </div> 
                 
            
                 
                 
                 
               <div class="row" v-show='rldata.is_report_form'>



                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">


                     
                          
                         <h5 class="mt-4 mb-3 ml-0 ">Отчет по заявке</h5>
                         
                        <b-input    v-model='rldata.ticket_num'  placeholder="Номер заявки"  > </b-input>
                         <div  v-if="rldata.flags_logic.is_act2_visible">
                                <b-button  class="btn-danger  ml-0 mb-2 mt-1" v-on:click="open_ticket" v-bind:disabled="rldata.flags_logic.is_act2_disabled"    >Открыть заявку</b-button>
                           </div>  
                        
                        <div v-if="rldata.is_loaded_data">
                        <p>Локация: {{ rldata.r_tickets.qrinfo }}</p>
                          <p class="mt-3 mb-2 ml-0 ">Приложено фото:</p> 
                            <div class="d-flex flex-row justify-content-left">
                                <div class="" style="">
                                    <img id="photo2" style="width:100%; max-width:300px;" v-bind:src="this.$root.rdata['img_cloud_url']+rldata.r_tickets.img_link">  
                                </div>
                            </div>
                        <p>Клиент: {{ rldata.r_tickets.client_problem }}</p>
                        <p>Менеджер: {{ rldata.r_tickets.manager_coment }}</p>
                        </div>  
                        
                      
                        <div>
                            <p class="mt-3 mb-2 ml-0 ">Приложите фотографию, если надо</p> 
                             <b-form-file  @change="getFile" accept="image/png,image/gif,image/jpeg" browse-text='Приложить фото' id="inpu8"  placeholder=""></b-form-file>
                            </div>
                        
                        <!--
                            <p class="mt-3 mb-2 ml-0 ">Приложите фотографию</p>  
                            <a href="#" class="mt-2 mb-2 ml-3 "  style="font-size: 14px;" v-on:click="camera_form">Приложить</a> 
                          -->
                          
                             <p class="mt-3 mb-2 ml-0 ">Приложено фото:</p> 
                            <div class="d-flex flex-row justify-content-left">
                                <div class="" style="">
                                    <img id="photo2" style="width:100%; max-width:300px;" v-bind:src="rldata.img_data">  
                                </div>
                            </div>
                            
                          
                         <div >
                                <b-button  class="btn-danger  ml-0 mb-2 mt-1" v-on:click="update_wokerpart_ticket" v-bind:disabled="check_is_act2_disabled">Заявка выполнена</b-button>
                           </div>    
                       
                         
                         


                          <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                          <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>


                     </div>
                   </div>
                 </div>























               </div>
             
                 
                 
                 
                 
  <div class="row" v-show='rldata.is_camera_form'>         
                 
           <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
      
       
                
                
              
                
                <div v-show="rldata.is_snap_photo" >
                 
                    <div class="">
                    
                    <!-- Рабочий код - выбор камеры
                    <p>Выберите камеру:</p>
                <button id="switchFrontBtn"  class="btn btn-link  ml-0 mb-2 mt-1"  v-on:click="switchFrontBtn_click">Передняя камера</button>
                <button id="switchBackBtn"  class="btn btn-link  ml-0 mb-2 mt-1"  v-on:click="switchBackBtn_click">Задняя камера</button> 
              -->
                   
                     
                    <h4 class="ml-0 mb-3">Камера</h4>
                    
                    
                    
                    <div v-show="rldata.is_show_takephoto_button" class="d-flex flex-row justify-content-center">
                        <div class="" style="width:320px;">
                            <video id="cam" autoplay muted playsinline style="width:320px;">Not available!!!</video>                
                            <canvas   id="canvas" style="display:none; width:320px;"></canvas>  
                        </div>
                    </div>
              
                    <div v-show="!rldata.is_show_takephoto_button">    
                        
                        <div v-if="!rldata.is_faild_get_cam">
                            <p class="ml-4">  Активация камеры...</p>
                        </div>
                        <div v-else>
                            <p class="ml-4">Не удалось активировать камеру</p>
                          
                            <div v-if="rldata.is_requested_device_not_found">
                                <p class="ml-4">Камера с обратной стороны устройства недоступна, приложить фото к заявке не получится.</p>
                            </div>
                            <div v-if="rldata.is_premission_denied">
                               <p class="ml-4">Разрешение на предоставление доступа к камере отклонено. В "Настройках Браузера" в разделе "Настроек сайта", необходимо дать "Разрешение" для "Камеры". Повторные запросы на предоставление разрешения к камере со стороны "сайта" запрещены политикой браузера.</p>
                            </div> 
                            <button id="switchBackBtn"  class="btn btn-danger  ml-5 mb-3 mt-1"  v-on:click="switchBackBtn_click">Попробовать еще раз</button> 
                            
                        </div>
                        
                    </div>
                    
                    
                    <div v-show="rldata.is_show_takephoto_button" class="d-flex flex-row justify-content-center">
                                <div v-if="rldata.is_show_takephoto_button" class=" mt-2">
                                  <a id="snapBtn"  href="#" class=" text-small  "  v-on:click="snapBtn_click">Сделать фото</a>
                                </div>
                    </div>
                    
                    <div class="d-flex flex-row mt-2">
                                <div class="ml-4">
                                    <a id="snapBtn" href="#"  class=" text-small "  v-on:click="ticket_form">Назад</a>
                                </div>
                                
                    </div>
                   
                    
                    
                  
                </div>
              </div><!-- comment -->
                
                <div v-show="rldata.is_result_photo">
                    
                    <h4 class="ml-0 mb-2">Фотография готова</h4>
                    
                    <div class="mb-3">
                        <a href="#" class="text-small "  v-on:click="remakephoto_click">Переделать фото</a> 
                    </div>
                    
                    <div class="d-flex flex-row justify-content-center">
                        <div class="" style="width:320px;">
                            <img id="photo" style="width: 320px;">  
                        </div>
                    </div>
                
                    <div class="d-flex flex-row mt-2">
                                <div v-if="rldata.is_show_takephoto_button" class="ml-4">
                                    <a href="#" class=" text-small  "  v-on:click="ticket_form">Готово</a> 
                                </div> 
                    </div>
                    
                </div>
             
  
                         
                         
                         
                         
  
           </div>  
         </div>  
        </div>  
  
  </div>               
                 

             
</div>



              <!-- COL1 -->  






       
       
       
      </div>
        
     
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
       
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';   


var data = {
    objcode : '',
    loccode : '', 
  
    
    ticket_num : '',
    r_qrcodes : [],
    r_objects : [],
    r_tickets : [],
    
    img_data : "",
    
  
    
    is_camera_form : false,
    is_report_form : true,
    is_fin_form : false,
   
    
    
    is_result_photo : true,
    is_snap_photo : false,
    is_loaded_data : false,
    is_faild_get_cam : true,
    is_requested_device_not_found : false,
    is_premission_denied : false, 
    
    
    is_show_takephoto_button : false,
    flags_logic: {
        is_act_disabled : false,
        is_act2_disabled : false,
        is_act2_visible: true,
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {
            //is_error_email_exist: false,
            //text_error_email_exist: "Пользователь с таким email уже зарегистрирован, пожалуйста авторизуйтесь. Если вы забыли свой пароль, проидет процедуру восстановления доступа.  ",
    },
    
   camera_type : null,
   mediaStream : null,
   //timer_check_is_loaded_data : 1, 
   
   corp : "",
   
   constraints : { 
    audio: false, 
    video: { 
      width: {ideal: 320}, 
      height: {ideal: 240},
      facingMode: "environment"
    }, 
  },
  
  
}      
  
import StarRating from 'vue-star-rating';
  
export default {
  name: 'addqr',  
components: {
  StarRating
},

  
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
   
   // this.$root.check_version(this.rldata, true);
   
   this.rldata['ticket_num']="";
   this.rldata['corp']= localStorage.getItem('corp');
   //!!!!!!если this.rldata['corp']!=='1122'  досвидос
   
       
    this.rldata['is_loaded_data']=false;
    this.rldata['flags_logic']['is_act_disabled']=false;
    this.rldata['flags_logic']['is_act2_disabled']=false;
    this.rldata['flags_logic']['is_act2_visible']=true;
   
       
         //получить данные о пользователе из ls
    var url = new URL(window.location.href);    
    let offticknum=url.searchParams.get("ticknum");  
    if (offticknum!==null)
    {
        //отметим в заявке, что это визит клиннера - в базу уйдет информация о гашении заявки
      this.off_ticket(offticknum);  
    }
    
    let open_ticket=url.searchParams.get("open_ticket");  
    if (open_ticket!==null)
    {   
        //если кланнер открывает заявку по ссылке из телеграмма - то подгрузить ему сразу данные по заявке
        this.rldata['ticket_num']=open_ticket;
        this.rldata['flags_logic']['is_act2_visible']=false;
        this.open_ticket();
    }
        
    
   

    
    
    this.rldata['is_camera_form']=false;
    this.rldata['is_report_form']=true;
    this.rldata['is_fin_form']=false; 
    
    this.rldata['is_result_photo']=true;
    this.rldata['is_snap_photo']=true;
    this.rldata['is_show_takephoto_button']=false;
    this.rldata['camera_type']=null;
    
    this.rldata['img_data'] = "";
      
   this.rldata['is_faild_get_cam']=true;  
   this.rldata['is_requested_device_not_found']=false;
   this.rldata['is_premission_denied']=false; 
   //функция, подтягивающия данные по objcode и loccode и создающая заявку................... с указанным IP
   //
          
   
   //this.rldata['timer_check_is_loaded_data']=1;
   
   
   
   
   
  
   
   
   
  
  },  
  
  
  
  
  
  
methods: {
     
     
     
                getBase64 : function(file) {
   var reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = function () {
     console.log(reader.result);
   };
   reader.onerror = function (error) {
     console.log('Error: ', error);
   };
},

      getFile : function (event)   //(event)  
       {
            
            console.log(event.target.files[0]);
            var file=event.target.files[0];
            
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event)  => {
              console.log(reader.result);
              this.rldata['img_data']=reader.result;
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
              this.rldata['img_data']="";
            };
          }, 
     
    
    
    
    off_ticket: function (ticknum) {      
    
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
      
                
            var url=this.$root.rdata['server_auth'];
                 
                 
             var params = {
                  method:  'common__corp_off_ticket', 
                   data:  {
                     ticknum : ticknum, 
                  } 
              }; 
       
       
    
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   //this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку 
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     //this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку 
            }).then(function () {  });
    
                
     
        
        
   
    
    
    },    
       
     
     
     
     
     
     
     
      camera_form : function ()   //(event) 
    {   
         this.rldata['is_camera_form']=true;
         this.rldata['is_report_form']=false;
         this.rldata['is_result_photo']=false;
         this.rldata['is_snap_photo']=true;
         
         if (this.rldata['camera_type']===null)
         {
             this.switchBackBtn_click();
         }
         
         //this.clearPhoto(); валиться
    },
      
      
    
  open_ticket : function ()   //(event) 
    {
    
    //is_act2_disabled
       this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
        if (!this.rldata['flags_logic']['is_act2_disabled'])
            {
                this.rldata['flags_logic']['is_act2_disabled']=true; //заблокируем кнопку
                
                
                 var url=this.$root.rdata['server_auth'];
                 
                 
             var params = {
                  method:  'common__corp_cleaner_open_ticket', 
                   data:  {
                     ticknum : this.rldata['ticket_num'], 
                  } 
              }; 
       
       
           this.rldata['r_tickets']=[];
           this.rldata['r_qrcodes']=[];
           this.rldata['r_objects']=[];  
           this.rldata['is_loaded_data']=false;
     
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                              //console.log(response['data']['data']['qrinfo']);
                                this.rldata['r_tickets']=response['data']['data']['r_tickets'];
                                this.rldata['r_qrcodes']=response['data']['data']['r_qrcodes'];
                                this.rldata['r_objects']=response['data']['data']['r_objects'];  
                                this.rldata['is_loaded_data']=true;
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break; 
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                            
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act2_disabled']=false; //разаблокируем кнопку 
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true; 
                     this.rldata['flags_logic']['is_act2_disabled']=false; //разаблокируем кнопку 
                      
                     
            }).then(function () {  });
       
                
                
            }
    
    //  this.$root.get_data_by_objcode_and_loccode(this.rldata); //a_qrcodes
      
      //this.rldata['objcode']=url.searchParams.get("o");             
      //this.rldata['loccode']=url.searchParams.get("l");
    
    
    
    },
    
    update_wokerpart_ticket : function ()   //(event) 
    {        
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
        if ( this.rldata['ticket_num']!=="" && this.rldata['img_data']!=="" && !this.rldata['flags_logic']['is_act_disabled'])
            {
                this.rldata['flags_logic']['is_act_disabled']=true; //заблокируем кнопку
                
                
                 var url=this.$root.rdata['server_auth'];
                 
                 
             var params = {
                  method:  'common__corp_update_wokerpart_ticket', 
                   data:  {
                     ticknum : this.rldata['ticket_num'], 
                     img_data : this.rldata['img_data']
                  } 
              }; 
       
       
    
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                           case "EMPTY_IMG":
                               
                               
                              console.log("READY");
                               
                              //console.log(response['data']['data']['qrinfo']);
                              
                             // ??? this.rldata['last_insert_qrinfo']=response['data']['data']['qrinfo'];
                              
                            this.rldata['is_camera_form']=false;
                            this.rldata['is_report_form']=false;
                            this.rldata['is_fin_form']=true; 
                            
                            
                            /*
                            try {  // stop the current video stream
                                if (this.rldata['mediaStream'] != null && this.rldata['mediaStream'].active) {
                                  var tracks = this.rldata['mediaStream'].getVideoTracks();
                                  tracks.forEach(track => {  track.stop(); })      
                                } 
                              } catch (err)  {     console.error(err.message);   }
                            */
                        
                 
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                        
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                   
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                    
                    
            }).then(function () {  });
      
                
                
            } 
        
        
    },
   
  
  
  
  
  
  
  





getMediaStream : async function (constraints)   
 {
     
this.rldata['is_show_takephoto_button']=false;
this.rldata['is_requested_device_not_found']=false;
this.rldata['is_premission_denied']=false; 

  try {
      
     
       
      
    this.rldata['mediaStream'] =  await navigator.mediaDevices.getUserMedia(constraints);
    
     
    
    
    
    let video = document.getElementById('cam');    
    video.srcObject = this.rldata['mediaStream'];
    video.onloadedmetadata = (event) => {
      video.play();
      
      this.rldata['is_show_takephoto_button']=true;
      
    };
    
    
    
  } catch (err)  {    
    console.error(err.message);  
    this.rldata['is_faild_get_cam']=true;
    this.rldata['is_requested_device_not_found']=true;
    this.rldata['is_premission_denied']=false;
    
    //alert(err.message);
    
    if (err.message==="Permission denied")
    { 
     this.rldata['is_premission_denied']=true;
     this.rldata['is_requested_device_not_found']=false;
    }
    
   
    
    
  }
  
  
},

switchCamera : async function (cameraMode) {  
  try {
    this.rldata['is_faild_get_cam']=false;  
      
    // stop the current video stream
    if (this.rldata['mediaStream'] != null && this.rldata['mediaStream'].active) {
      var tracks = this.rldata['mediaStream'].getVideoTracks();
      tracks.forEach(track => {
        track.stop();
      })      
    }
    
    // set the video source to null
    document.getElementById('cam').srcObject = null;
    
    // change "facingMode"
    this.rldata['constraints'].video.facingMode = cameraMode;
    
    // get new media stream
    await this.getMediaStream(this.rldata['constraints']);
  } catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
},

takePicture : function () {  
  let canvas = document.getElementById('canvas');
  let video = document.getElementById('cam');
  let photo = document.getElementById('photo');  
  let context = canvas.getContext('2d');
  
  const height = video.videoHeight;
  const width = video.videoWidth;
  
  if (width && height) {    
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);    
    var data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
    
    this.rldata['img_data']=data;
    
  } else {
    this.clearphoto();
  }
},

clearPhoto : function() {
  let canvas = document.getElementById('canvas');
  let photo = document.getElementById('photo');
  let context = canvas.getContext('2d');
  
  context.fillStyle = "#AAA";
  context.fillRect(0, 0, canvas.width, canvas.height);
  var data = canvas.toDataURL('image/png');
  photo.setAttribute('src', data);
},


  
 
switchFrontBtn_click : function() {
   
   this.rldata['camera_type']="user";
        this.switchCamera(this.rldata['camera_type']);
        
},
 
switchBackBtn_click : function() {
   this.rldata['camera_type']="environment";
      this.switchCamera(this.rldata['camera_type']);
        
      
},

snapBtn_click : function() {
       
       
 try{
        
      
      this.takePicture();
      this.rldata['is_snap_photo']=false;
      this.rldata['is_result_photo']=true;
      
    } 
    catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
      
      
       //this.event.preventDefault();
},                              
 
remakephoto_click : function() {
       
        try{
      
       this.clearPhoto();
       this.rldata['is_result_photo']=false;
       this.rldata['is_snap_photo']=true;
   
        } 
    catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
}, 
 
 ticket_form : function ()   //(event) 
    {    
         this.rldata['is_snap_photo']=true;
         this.rldata['is_result_photo']=false;
         this.rldata['is_camera_form']=false;
         this.rldata['is_report_form']=true;
         
         
         this.rldata['is_fin_form']=false;
       
    },
     
  
  
  
  
  
  
  
  
  
  
  
  
  
  
},
  
  
 
   computed: { 
     
                
             check_is_act2_disabled () { 
    //проверяет доступна ли кнопка действия (галка + должно быть завершено предидущее действие кнопки)
     if (this.rldata['ticket_num']!=="" && this.rldata['img_data']!=="" && !this.rldata['flags_logic']['is_act_disabled'] ){
        return false;//true - заблокирована
     }
     return true;
    },    
  
},



/*
  watch: {   
      
       "rldata.is_camera_form": {
                handler(value) {

                   
                        if (this.rldata['is_camera_form'])
                            {
                         
                                    
                          
                           }
                        
                    }
                }
            },*/
  
}
  

</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .btn_custom_width {
        width: 120px !important;
    }
   
    
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}  
  
</style>